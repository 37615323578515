import axiosInstance from '~/plusgin/axios';

// 登录方法
export function login(phoneNumber, code, uuid) {
  return axiosInstance.request({
    url: `/${import.meta.env.VITE_APP_API_PREFIX}/login`,
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: { phoneNumber, captcha: code, uuid }
  })
}

// 注册方法
export function register(data) {
  return axiosInstance.request({
    url: '/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 刷新方法
export function refreshToken() {
  return axiosInstance.request({
    url: '/auth/refresh',
    method: 'post'
  })
}

// 获取用户详细信息
export function getInfo() {
  return axiosInstance.request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}


// 退出方法
export function logout() {
  return axiosInstance.request({
    url: '/auth/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return axiosInstance.request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 获取短信验证码
export function getSMSCode(receiver, uuid) {
  return axiosInstance.request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000,
    params: {
      type: "sms",
      receiver,
      uuid
    }
  })
}