import axios from 'axios';
import { getToken } from '@/utils/auth'
import { defineStore } from "pinia"; // 引入pinia

const axiosInstance = axios.create({
  // 请求超时时间
  timeout: 15000
})

//  stores/user
export const useUserStateStore = defineStore("main", {
  state: () => {
    return {
      Authorization: "",
    };
  },
  persist: process.client && { // 仅在客户端使用
    storage: localStorage.getItem('Authorization'), // localStorage 本地存储，可替换sessionStorage
  },
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    //设置基础路径
    config.baseURL = useRuntimeConfig()['public'].baseUrl
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 获取 Vuex 中的 token
    // 设置 Authorization 请求头
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // 返回修改后的配置
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 如果 response.code 是 401，则跳转到登录页
    if (response.data && response.data.code>=400 && response.data.code <= 600) {
      if(response.data.code<1000) {
        if(response.data.code === 401) {
          const router = useRouter();
          router.push({ path: '/user/login.html' });  
        }
        return Promise.reject(response);  // 返回一个 rejected promise
      }
    }
    return response.data;
  },
  (error) => {
    // 如果是请求返回了错误信息，也可以在这里处理
    if (error.response && error.response.data && error.response.data.code === 401) {
      const router = useRouter();
      router.push({ path: '/user/login.html' }); 
      return Promise.reject(error);  // 返回一个 rejected promise
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;