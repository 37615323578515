import { default as detail_46htmlYDBK5eSCOZMeta } from "/data/project/live/vue/zkjiadi-live-web/pages/class/detail.html.vue?macro=true";
import { default as index_46htmlZ69J4JGT14Meta } from "/data/project/live/vue/zkjiadi-live-web/pages/class/index.html.vue?macro=true";
import { default as play_46html2BDtGuQOAeMeta } from "/data/project/live/vue/zkjiadi-live-web/pages/class/play.html.vue?macro=true";
import { default as indexnZCKmnoFZWMeta } from "/data/project/live/vue/zkjiadi-live-web/pages/index.vue?macro=true";
import { default as index_46htmlSjJZ5xnSeqMeta } from "/data/project/live/vue/zkjiadi-live-web/pages/live/index.html.vue?macro=true";
import { default as login_46htmloWh398ek4xMeta } from "/data/project/live/vue/zkjiadi-live-web/pages/user/login.html.vue?macro=true";
export default [
  {
    name: "class-detail.html",
    path: "/class/detail.html",
    meta: detail_46htmlYDBK5eSCOZMeta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/class/detail.html.vue").then(m => m.default || m)
  },
  {
    name: "class-index.html",
    path: "/class/index.html",
    meta: index_46htmlZ69J4JGT14Meta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/class/index.html.vue").then(m => m.default || m)
  },
  {
    name: "class-play.html",
    path: "/class/play.html",
    meta: play_46html2BDtGuQOAeMeta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/class/play.html.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexnZCKmnoFZWMeta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "live-index.html",
    path: "/live/index.html",
    meta: index_46htmlSjJZ5xnSeqMeta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/live/index.html.vue").then(m => m.default || m)
  },
  {
    name: "user-login.html",
    path: "/user/login.html",
    meta: login_46htmloWh398ek4xMeta || {},
    component: () => import("/data/project/live/vue/zkjiadi-live-web/pages/user/login.html.vue").then(m => m.default || m)
  }
]