import revive_payload_client_aVXHGjRFe6 from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DDdpwIunP9 from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QumEXK9m4T from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cKvIjW4Qn6 from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XE45NaAzin from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pmAYehHVkL from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HgEIhr1ln5 from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xa3geiZrBS from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/data/project/live/vue/zkjiadi-live-web/.nuxt/components.plugin.mjs";
import prefetch_client_8bvB5RJIVV from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k7eflj2qkeougnclpfc6k66ace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/data/project/live/vue/zkjiadi-live-web/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/data/project/live/vue/zkjiadi-live-web/.nuxt/element-plus-injection.plugin.mjs";
import plugin_9SKbmy12Cs from "/data/project/live/vue/zkjiadi-live-web/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_vue@3._lw7psrvbajerrubrkuluvogj6y/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_aVXHGjRFe6,
  unhead_DDdpwIunP9,
  router_QumEXK9m4T,
  payload_client_cKvIjW4Qn6,
  navigation_repaint_client_XE45NaAzin,
  check_outdated_build_client_pmAYehHVkL,
  chunk_reload_client_HgEIhr1ln5,
  plugin_vue3_xa3geiZrBS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8bvB5RJIVV,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_9SKbmy12Cs
]