import { ElMessage } from 'element-plus'
import { getToken } from '@/utils/auth'
import { isHttp } from '@/utils/validate'
import { isRelogin } from '@/utils/request'
import useUserStore from '@/store/modules/user'
import { useRouter } from 'vue-router'

const whiteList = ['/', '/user/login.html', '/class/detail.html'];

export default defineNuxtRouteMiddleware((to, from) => {
    if (import.meta.server) {
        console.log('server 运行时机');
    }
    if (import.meta.client) {
        console.log('client 运行时机');
        const router = useRouter();
        if (getToken()) {
            if (useUserStore().roles.length === 0) {
                isRelogin.show = true
                // 判断当前用户是否已拉取完user_info信息
                useUserStore().getInfo().then((resp) => {
                    isRelogin.show = false
                }).catch(err => {
                    useUserStore().logOut().then(() => {
                        ElMessage.error(err)
                        // router.push({ path: '/' })
                    })
                })
            } else {
                // router.push()
            }
        } else {
            // 没有token
            if (whiteList.indexOf(to.path) !== -1) {
                // 在免登录白名单，直接进入
            } else {
                // router.push({ path: `/user/login.html` }).catch(console.error) // 否则全部重定向到登录页
            }
        }
    }
    const nuxtApp = useNuxtApp();
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
        console.log('仅在客户端首次加载时跳过中间件');
    }
})