import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import defAva from '@/public/images/logo-07.png'

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      id: null,
      userName: null,
      nickName: null,
      avatar: null,
      roles: [],
      permissions: []
    }),
    actions: {
      // 登录
      login(userInfo) {
        const phoneNumber = (userInfo.phoneNumber || '').trim()
        const code = userInfo.code
        const uuid = userInfo.uuid
        return new Promise((resolve, reject) => {
          login(phoneNumber, code, uuid).then(res => {
            setToken(res.data.access_token)
            this.token = res.data.access_token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          getInfo().then(res => {
            const user = res.user
            const avatar = (user.avatar == "" || user.avatar == null) ? defAva : user.avatar.startsWith("http") ? user.avatar : (import.meta.env.VITE_APP_BASE_API + user.avatar);

            if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              this.roles = res.roles
              this.permissions = res.permissions
            } else {
              this.roles = ['ROLE_DEFAULT']
            }
            this.id = user.userId
            this.userName = user.userName
            this.nickName = user.nickName
            this.avatar = avatar
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          logout(this.token).then(() => {
            this.token = null;
            this.id = null
            this.userName = null
            this.nickName = null
            this.avatar = null
            this.roles = []
            this.permissions = []
            removeToken()
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      }
    },
    persist: true
  })

export default useUserStore
